.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  margin: 0px;
  position: relative;
  cursor:default;
}

.ql-container.ql-snow {
  border: 0px solid #ccc;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-toolbar.ql-snow {
  border: 0px solid transparent;
  border-bottom: 1px solid rgb(240, 240, 239);
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
}

.non-editable .ql-editor {
  padding: 0px 0px;
}

.ql-mention-list-container {
  max-height: 200px;
}

.ql-editor img {
  cursor: pointer;
}
